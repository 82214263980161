import { parseIso8601Date, parseJson } from '../../util/parse';

type serverSettings = Readonly<{
	applicationPath: string;
	serverTimestamp: string;
	maximumUploadSizeInBytes: number;
	maximumListPageSize: number;
	minimumPasswordLength: number;
	exportPageSize: number;
	currentCulture: Readonly<{
		name: string;
		numberDecimalSeparator: string;
		numberGroupSeparator: string
	}>;
	iconPrefix: string;
	currentUser: Readonly<Record<string, any>>;
	clientIPLog: string;
	customizeMode: boolean;
	cookieNames: Readonly<{
		erpDefaultWhatIfGroup: string;
		menuActiveExpand: string;
		menuRecentItems: string;
		menuIsExpanded: string;
		menuLocation: string;
		topMenuActiveId: string;
		workflowMessage: string;
	}>;
	showMenu: boolean;
	monacoPath: string,
	undefinedString: string,
	session: Readonly<Record<string, any>>;
	strings: Readonly<{
		activeFormError: string;
		addItem: string;
		aMomentAgo: string;
		archive: string;
		anotherFilterItemUsesThisScriptName: string;
		confirmArchive: string;
		confirmRemoveFieldGroup: string;
		confirmRemoveList: string;
		confirmClearUserFilter: string;
		createItem: string;
		daysAgo: string;
		editExpression: string;
		editScript: string;
		end: string;
		error: string;
		expressionError: string;
		fieldMustHaveAValueBetween: string;
		fieldMustNotStartWithUnderscore: string;
		fieldMustStartWithUnderscore: string;
		fileIsStillUploadingWarning: string;
		fileSizeExceeded: string;
		fileUploadFailed: string;
		filterLabelPrompt: string;
		filterScriptNamePrompt: string;
		hoursAgo: string;
		invalidField: string;
		layoutChangedWarning: string;
		listExportLimited: string;
		listHasChanges: string;
		listItemsNotValid: string;
		listSelectionLimited: string;
		loadingMessage: string;
		markAllAsRead: string;
		maximumMustBeGreaterOrEqualToMinimum: string;
		minutesAgo: string;
		monthsAgo: string;
		no: string;
		nullText: string;
		objectCannotHaveIndexes: string;
		passwordIsTooShort: string;
		passwordMustDiffer: string;
		passwordsDoNotMatch: string;
		recordIsAlreadyArchived: string;
		requiredFieldMessage: string;
		saving: string;
		search: string;
		select: string;
		selectionsAreNotValid: string;
		start: string;
		unsavedChangesWarning: string;
		updating: string;
		valueCannotBeZero: string;
		yearsAgo: string;
		yes: string;
		pasteRowsPrompt: string;
		pasteRowsNoClipboardData: string;
		pasteRowsSummary: string;
		pasteRowsDiscardSummary: string;
		treeLoadError: string;
		noData: string;
		uniqueFieldMessage: string;
		userFilterSaved: string;
		userFilterSaveError: string;
		userFilterCleared: string;
		userFilterClearError: string;
	}>;
}>;

type clientSettings = {
	clientTimeOffset: number;
	inputSearchDelay: number;
}

type exportSettings = serverSettings & clientSettings;

export let settings: exportSettings;

export async function loadConfig(data: string) {
	settings = parseJson<exportSettings>(data);
	settings.clientTimeOffset = getClientTimeOffset(parseIso8601Date(settings.serverTimestamp));
	settings.inputSearchDelay = 400;

	const culture = settings.currentCulture.name;

	await loadCulture(culture);
}

async function loadCulture(culture: string) {
	switch (culture) {
		case 'tr':
			await import('@progress/kendo-ui/js/cultures/kendo.culture.tr.js');
			break;

		case 'tr-TR':
			await Promise.all([
				import('@progress/kendo-ui/js/cultures/kendo.culture.tr-TR.js'),
				import('@progress/kendo-ui/js/messages/kendo.messages.tr-TR.js')
			]);
			break;

		case 'en-GB':
			await Promise.all([
				import('@progress/kendo-ui/js/cultures/kendo.culture.en-GB.js'),
				import('@progress/kendo-ui/js/messages/kendo.messages.en-GB.js')
			]);
			break;

		case 'en-IE':
			await import('@progress/kendo-ui/js/cultures/kendo.culture.en-IE.js');
			break;

		default:
			culture = 'en-US';
			break;
	}

	if (kendo.culture().name !== culture) {
		kendo.culture(culture);
	}

	kendo.culture().numberFormat.currency.symbol = "";
}

function getClientTimeOffset(date: Date): number {
	if (date != null) {
		const minute = 60 * 1000;

		return Math.round((new Date().getTime() - date.getTime()) / minute) * minute;
	}

	return 0;
}
